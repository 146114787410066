.Hero {

  margin-top: -120px;
  z-index: -10;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }


  /// CUT
  .shape-div {
    width: 102%;
    //height: 500px; /* Adjust the height as needed */
    background-color: #FFF; /* Adjust the background color as needed */
    clip-path: url(#clip-shape);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 60vh;
    margin-top: -8vh;
    z-index: 10;
  }

  /// END CUT

  /////
  margin-bottom: 0;

  @mixin content-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 70%;

    p {
      font-size: 28px;
      color: var(--fg-white);
      line-height: 42px;

    }

    .signature {
      color: var(--fg-white);
      width: 100%;
      text-align: right !important;
      float: right;
    }

  }

  @mixin overlay {
    min-height: 15vh;
    z-index: 20;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-overlay-top {
    @include overlay;
  }

  .content-overlay-bottom {
    @include overlay;
    margin-top: -8vh;
  }

   .content-image {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      height: 70vh;
      margin-top: -8vh;
      z-index: 10;
    }

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      //gap: 140px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);



      .content-left {
        @include content-block;
        align-items: flex-end;
        float: right;
      }
      .content-right {
        @include content-block;
        align-items: flex-start;
        float: left;
    }
  }

  .brooks {
    $base-font-size: 2.4em;
    $base-line-height: 1.5em;
    padding-top: 40px;
    position: relative;
    font-family: 'Sanchez', serif;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-style: italic;
    &:before {
      content: '\201C';
      position: absolute;
      z-index: 10000;
      top: 0.3em;
      left: -0.25em;
      color: rgba(255,255,255,0.4); //darken(#FFF, 7%);
      font-size: 7em;

    }
  }

  .mobile {
    display: none;
  }

  //.video-style {
  //  width: 100%;
  //  min-height: 40vh;
  //}


  // S - Tablet
  @media (max-width: 768px) {
    margin-top: 120px;
    .shape-div {
      height: 45vh;
      margin-top: -5vh;
    }

    .mobile {
      padding: 20px;
      display: block;
      font-size: 8px !important;
      color: #FFF;
    }

    .desktop {
      display: none !important;
    }

  }

  // XS - Phone
  @media (max-width: 480px) {
    margin-top: 90px;
    .shape-div {
      height: 40vh;
      margin-top: -3vh;
    }
  }


  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
