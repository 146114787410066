.Blob {
  border-radius: 49% 51% 65% 35% / 67% 53% 47% 33%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-object-type='small'] {
    $scale: 0.4;
    width: 1439px * $scale;
    height: 1075px * $scale;
    background-color: var(--bg-light-pink);
  }

  //&[data-object-type='large'] {
  //  $scale: 0.7;
  //  width: 1439px * $scale;
  //  height: 1075px * $scale;
  //  background-color: var(--bg-dark-gray);
  //}


  // XS - Phone


  // S - Tablet
  @media (max-width: 1024px) {

    &[data-object-type='small'] {
      padding: 80px;
      width: 100%;
      background-color: var(--bg-light-pink);
      margin: 0;
      height: auto;
    }


  }

  @media (max-width: 480px) {
    padding: 0;
    gap: 0;
    display: block;
    &[data-object-type='small'] {
      padding: 30px 20px !important;
      margin-left: -10px;
      margin-right: -10px;
      width: 110%;
      height: auto;
      background-color: var(--bg-light-pink);
      //margin: 0;
      border-radius: 0;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {
  }

  // L - High Res
  @media (max-width: 1200px) {
  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {
  }
}
