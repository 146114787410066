.float-right {
    justify-content: flex-end;
}

.float-start {
    justify-content: flex-start;
}

.float-center {
    justify-content: center;
}
