.Logo {
    $size: 220px;

    width: $size;
    height: $size;



    // S - Tablet
    @media (max-width: 768px) {
      $size: 140px;

      width: $size;
      height: $size;
    }

  // XS - Phone
  @media (max-width: 480px) {
    $size: 80px;

    width: $size;
    height: $size;
  }
}
