.Menu {

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .menu-desktop {
    display: block;
  }

  .menu-mobile {
    display: none;

    .menu-trigger {
      z-index: 10001;
      position: fixed;
      top: 10px;
      right: 10px;
      background-color: var(--bg-white);
      padding: 4px;
    }

    .overlay {
      z-index: 1000;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      padding-top: 100px;
      background-color: rgba(255, 255, 255, 0.9);
      transition: var(--transition);

      ul li {
        width: 80%;
        text-align: center !important;
      }
    }
  }






    // S - Tablet
    @media (max-width: 768px) {
      .menu-desktop {
        display: none;
      }
      .menu-mobile {
        display: block;
      }
    }

    // XS - Phone
    @media (max-width: 480px) {
      .menu-desktop {
        display: none;
      }
      .menu-mobile {
        display: block;
      }
    }

}
