.SignatureTitle {

  font-family: 'heading', sans-serif;


  &[data-object-type='signature'] {
    color: var(--fg-white);
    font-size: 60px;
  }

  &[data-object-type='title'] {
    color: var(--fg-pink);
    font-size: 60px;
    margin-bottom: -30px;
  }

  &[data-object-type='prompt'] {
    color: var(--fg-white);
    font-size: 40px;
  }


  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {
    &[data-object-type='signature'] {
      color: var(--fg-white);
      font-size: 30px;
      text-align: right;
    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}