.MenuItem {
    float: left;
    display: block;
    margin: 40px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    padding-bottom: 6px;

    a {
        color: var(--fg-black);
        transition: border-bottom 0s;
        padding-bottom: 6px;
        border-bottom: 4px solid transparent;

      &:hover {
          color: var(--fg-pink);
          border-bottom: 4px solid var(--fg-black);
        }
    }


  .active {
    color: var(--fg-pink);
    border-bottom: 4px solid var(--fg-black);
  }

    // XS - Phone
    @media (max-width: 480px) {
    }

    // S - Tablet
    @media (max-width: 768px) {
    }

    // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
