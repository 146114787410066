.Booking {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;

  .ButtonBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  // XS - Phone
  @media (max-width: 480px) {
    width: 300px;
    max-width: 400px;
  }

  // S - Tablet
  @media (max-width: 768px) {
    //margin-left: -40px;
    width: 100%;
    max-width: 100%;
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}