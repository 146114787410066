.Heading {

  font-family: 'main-text-bold', sans-serif;
  color: var(--fg-pink);

  padding-left: 80px;
  padding-right: 60px;
  margin-bottom: 20px;

  &[data-object-type='h1'] { font-size: 2.6rem;}
  &[data-object-type='h2'] { font-size: 2rem;}
  &[data-object-type='h3'] { font-size: 1.8rem;}
  &[data-object-type='h4'] { font-size: 1.6rem;}

  // S - Tablet
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  // XS - Phone
  @media (max-width: 480px) {
    &[data-object-type='h1'] { font-size: 2.6rem;}
    &[data-object-type='h2'] { font-size: 2rem;}
    &[data-object-type='h3'] { font-size: 1.8rem;}
    &[data-object-type='h4'] { font-size: 1.6rem;}

  }



  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}