$h_type: map-get($headings, 'type');
$h_uri: map-get($headings, 'uri');
/*! heading */
@font-face {
    font-family: '#{$h_type}';
    src:
        url($h_uri + '.woff') format('woff'),
        /* Modern Browsers */ url($h_uri + '.woff2') format('woff2'),
        /* Modern Browsers */;
}
