.Header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 40px;
    z-index: 1000;
    position: relative;
  left:0;
  top: 0;
  right: 0;





    // S - Tablet
    @media (max-width: 768px) {
      position: fixed;
      padding: 10px 20px;
    }

    // XS - Phone
    @media (max-width: 480px) {
      padding: 4px 10px;
    }


  // M - Tablet/Laptop
    @media (max-width: 1024px) {
    }

    // L - High Res
    @media (max-width: 1200px) {
    }

    // XL - Ultra High Res
    @media (min-width: 1201px) {
    }
}
