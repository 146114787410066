.Button {

  background-color: var(--bg-pink);
  color: var(--fg-white);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 15px;
  display: inline-block;
  //min-width: 180px;
  //min-height: 50px;
  text-align: center;
  transition: var(--transition);

  &:hover {
    color: var(--fg-white);
    background-color: var(--fg-pink-dark-20);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
  }

  &[data-object-type='secondary'] {
    background-color: transparent;
    padding: 15px 0 0;
    color: var(--fg-pink);
    border-bottom: 2px solid transparent;

    &:hover {
      color: var(--fg-white);
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
      padding-bottom: 4px;
      border-bottom: 2px solid var(--fg-pink);

    }
  }

    // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}