.BottomBlob {

  min-width: 100%;
  min-height: 700px;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-right: -20px;
  padding-right: 20px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 1025px) {
    padding-top: 30px;
    padding-right: 20%;
  }

  // S - Tablet
  @media (max-width: 1024px) {
    background-color: var(--bg-dark-gray);
    min-height: 200px;
    margin-left: -12px;
    margin-right: -12px;
    padding-right: 53px;
    padding-left: 20px;
  }

  // XS - Phone
  @media (max-width: 480px) {
    padding-right: 30px;
    padding-left: 30px;
  }



}