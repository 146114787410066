.BookAdvertising {

  max-width: 400px;
  padding-top: 40px;

  .book-info {
    margin: 20px 0;
    p {
      color: var(--fg-white);
    }
  }

  .book-description {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    .book-content {

      h3 {
        margin: 20px 0;
        font-family: 'main-text-bold', sans-serif;
        font-size: 24px;

        .label {
          color: var(--fg-white)
        }

        .price {
          color: var(--fg-pink)
        }
      }
    }

    .book-image {
      width: 120px;
      margin-right: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  // XS - Phone
  @media (max-width: 480px) {
    .book-description {
      .book-image {
        display: none;
      }
    }
  }

  // S - Tablet
  @media (max-width: 768px) {

  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}