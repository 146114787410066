@charset "UTF-8";
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
a {
  text-decoration: none;
}

/** FONTS **/
/*! main-text-regular */
@font-face {
  font-family: "main-text-regular";
  src: url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.woff2") format("woff2");
}
/*! main-text-bold */
@font-face {
  font-family: "main-text-bold";
  src: url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.woff2") format("woff2");
}
/*! main-text-link */
@font-face {
  font-family: "main-text-link";
  src: url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.woff2") format("woff2");
}
/*! label-text */
@font-face {
  font-family: "label-text";
  src: url("../fonts/HelveticaNeue.woff") format("woff"), url("../fonts/HelveticaNeue.woff2") format("woff2");
}
/*! heading */
@font-face {
  font-family: "heading";
  src: url("../fonts/dawning.woff") format("woff"), url("../fonts/dawning.woff2") format("woff2");
}
/** CUSTOM PROPERTIES **/
:root {
  /** COLOR **/
  /*! Background Color */
  /*! black */
  --bg-black: #000;
  --bg-black-light-80: #cccccc;
  --bg-black-light-90: rgb(229.5, 229.5, 229.5);
  --bg-black-dark-80: black;
  --bg-black-dark-90: black;
  /*! highlight-black */
  --bg-highlight-black: #212121;
  /*! white */
  --bg-white: #FFF;
  /*! white-muted */
  --bg-white-muted: rgba(255, 255, 255, 0.6);
  /*! pink */
  --bg-pink: #F0047F;
  --bg-pink-light-20: rgb(252.3114754098, 93.6885245902, 176.3606557377);
  --bg-pink-light-30: rgb(253.1475409836, 143.8524590164, 200.8155737705);
  --bg-pink-dark-20: rgb(139.6721311475, 2.3278688525, 73.9098360656);
  --bg-pink-dark-30: rgb(89.5081967213, 1.4918032787, 47.3647540984);
  /*! light-pink */
  --bg-light-pink: #FEE0EF;
  --bg-light-pink-light-10: white;
  --bg-light-pink-light-20: white;
  --bg-light-pink-light-30: white;
  --bg-light-pink-dark-10: rgb(252.40625, 174.59375, 213.5);
  --bg-light-pink-dark-20: rgb(250.8125, 125.1875, 188);
  --bg-light-pink-dark-30: rgb(249.21875, 75.78125, 162.5);
  /*! dark-gray */
  --bg-dark-gray: #374A54;
  --bg-dark-gray-light-10: rgb(75.1798561151, 101.1510791367, 114.8201438849);
  --bg-dark-gray-light-20: rgb(95.3597122302, 128.3021582734, 145.6402877698);
  --bg-dark-gray-light-30: rgb(123.2589928058, 153.0575539568, 168.7410071942);
  --bg-dark-gray-dark-10: rgb(34.8201438849, 46.8489208633, 53.1798561151);
  --bg-dark-gray-dark-20: rgb(14.6402877698, 19.6978417266, 22.3597122302);
  --bg-dark-gray-dark-30: black;
  /*! Foreground Color */
  /*! black */
  --fg-black: #000;
  --fg-black-light-80: #cccccc;
  --fg-black-light-90: rgb(229.5, 229.5, 229.5);
  --fg-black-dark-80: black;
  --fg-black-dark-90: black;
  /*! highlight-black */
  --fg-highlight-black: #212121;
  /*! white */
  --fg-white: #FFF;
  /*! white-muted */
  --fg-white-muted: rgba(255, 255, 255, 0.6);
  /*! pink */
  --fg-pink: #F0047F;
  --fg-pink-light-20: rgb(252.3114754098, 93.6885245902, 176.3606557377);
  --fg-pink-light-30: rgb(253.1475409836, 143.8524590164, 200.8155737705);
  --fg-pink-dark-20: rgb(139.6721311475, 2.3278688525, 73.9098360656);
  --fg-pink-dark-30: rgb(89.5081967213, 1.4918032787, 47.3647540984);
  /*! light-pink */
  --fg-light-pink: #FEE0EF;
  --fg-light-pink-light-10: white;
  --fg-light-pink-light-20: white;
  --fg-light-pink-light-30: white;
  --fg-light-pink-dark-10: rgb(252.40625, 174.59375, 213.5);
  --fg-light-pink-dark-20: rgb(250.8125, 125.1875, 188);
  --fg-light-pink-dark-30: rgb(249.21875, 75.78125, 162.5);
  /*! dark-gray */
  --fg-dark-gray: #374A54;
  --fg-dark-gray-light-10: rgb(75.1798561151, 101.1510791367, 114.8201438849);
  --fg-dark-gray-light-20: rgb(95.3597122302, 128.3021582734, 145.6402877698);
  --fg-dark-gray-light-30: rgb(123.2589928058, 153.0575539568, 168.7410071942);
  --fg-dark-gray-dark-10: rgb(34.8201438849, 46.8489208633, 53.1798561151);
  --fg-dark-gray-dark-20: rgb(14.6402877698, 19.6978417266, 22.3597122302);
  --fg-dark-gray-dark-30: black;
  /** FONT **/
  --main-text-regular: "main-text-regular";
  --main-text-bold: "main-text-bold";
  --main-text-link: "main-text-link";
  --label-text: "label-text";
  --transition: all 0.4s ease;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-white);
  font-family: "main-text-regular", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

/** UTILITY: Colors **/
/*! Background Color */
/*! black */
.bg-black {
  background-color: var(--bg-black);
}

.bg-black-light-80 {
  background-color: var(--bg-black-light-80);
}

.bg-black-light-90 {
  background-color: var(--bg-black-light-90);
}

.bg-black-dark-80 {
  background-color: var(--bg-black-dark-80);
}

.bg-black-dark-90 {
  background-color: var(--bg-black-dark-90);
}

/*! highlight-black */
.bg-highlight-black {
  background-color: var(--bg-highlight-black);
}

/*! white */
.bg-white {
  background-color: var(--bg-white);
}

/*! white-muted */
.bg-white-muted {
  background-color: var(--bg-white-muted);
}

/*! pink */
.bg-pink {
  background-color: var(--bg-pink);
}

.bg-pink-light-20 {
  background-color: var(--bg-pink-light-20);
}

.bg-pink-light-30 {
  background-color: var(--bg-pink-light-30);
}

.bg-pink-dark-20 {
  background-color: var(--bg-pink-dark-20);
}

.bg-pink-dark-30 {
  background-color: var(--bg-pink-dark-30);
}

/*! light-pink */
.bg-light-pink {
  background-color: var(--bg-light-pink);
}

.bg-light-pink-light-10 {
  background-color: var(--bg-light-pink-light-10);
}

.bg-light-pink-light-20 {
  background-color: var(--bg-light-pink-light-20);
}

.bg-light-pink-light-30 {
  background-color: var(--bg-light-pink-light-30);
}

.bg-light-pink-dark-10 {
  background-color: var(--bg-light-pink-dark-10);
}

.bg-light-pink-dark-20 {
  background-color: var(--bg-light-pink-dark-20);
}

.bg-light-pink-dark-30 {
  background-color: var(--bg-light-pink-dark-30);
}

/*! dark-gray */
.bg-dark-gray {
  background-color: var(--bg-dark-gray);
}

.bg-dark-gray-light-10 {
  background-color: var(--bg-dark-gray-light-10);
}

.bg-dark-gray-light-20 {
  background-color: var(--bg-dark-gray-light-20);
}

.bg-dark-gray-light-30 {
  background-color: var(--bg-dark-gray-light-30);
}

.bg-dark-gray-dark-10 {
  background-color: var(--bg-dark-gray-dark-10);
}

.bg-dark-gray-dark-20 {
  background-color: var(--bg-dark-gray-dark-20);
}

.bg-dark-gray-dark-30 {
  background-color: var(--bg-dark-gray-dark-30);
}

/*! Foreground Color */
/*! black */
.fg-black {
  color: var(--fg-black);
}

.fg-black-light-80 {
  color: var(--fg-black-light-80);
}

.fg-black-light-90 {
  color: var(--fg-black-light-90);
}

.fg-black-dark-80 {
  color: var(--fg-black-dark-80);
}

.fg-black-dark-90 {
  color: var(--fg-black-dark-90);
}

/*! highlight-black */
.fg-highlight-black {
  color: var(--fg-highlight-black);
}

/*! white */
.fg-white {
  color: var(--fg-white);
}

/*! white-muted */
.fg-white-muted {
  color: var(--fg-white-muted);
}

/*! pink */
.fg-pink {
  color: var(--fg-pink);
}

.fg-pink-light-20 {
  color: var(--fg-pink-light-20);
}

.fg-pink-light-30 {
  color: var(--fg-pink-light-30);
}

.fg-pink-dark-20 {
  color: var(--fg-pink-dark-20);
}

.fg-pink-dark-30 {
  color: var(--fg-pink-dark-30);
}

/*! light-pink */
.fg-light-pink {
  color: var(--fg-light-pink);
}

.fg-light-pink-light-10 {
  color: var(--fg-light-pink-light-10);
}

.fg-light-pink-light-20 {
  color: var(--fg-light-pink-light-20);
}

.fg-light-pink-light-30 {
  color: var(--fg-light-pink-light-30);
}

.fg-light-pink-dark-10 {
  color: var(--fg-light-pink-dark-10);
}

.fg-light-pink-dark-20 {
  color: var(--fg-light-pink-dark-20);
}

.fg-light-pink-dark-30 {
  color: var(--fg-light-pink-dark-30);
}

/*! dark-gray */
.fg-dark-gray {
  color: var(--fg-dark-gray);
}

.fg-dark-gray-light-10 {
  color: var(--fg-dark-gray-light-10);
}

.fg-dark-gray-light-20 {
  color: var(--fg-dark-gray-light-20);
}

.fg-dark-gray-light-30 {
  color: var(--fg-dark-gray-light-30);
}

.fg-dark-gray-dark-10 {
  color: var(--fg-dark-gray-dark-10);
}

.fg-dark-gray-dark-20 {
  color: var(--fg-dark-gray-dark-20);
}

.fg-dark-gray-dark-30 {
  color: var(--fg-dark-gray-dark-30);
}

.main-text-regular-12 {
  font-family: "main-text-regular";
  font-size: 0.75rem;
}

.main-text-regular-14 {
  font-family: "main-text-regular";
  font-size: 0.875rem;
}

.main-text-regular-16 {
  font-family: "main-text-regular";
  font-size: 1rem;
}

.main-text-regular-18 {
  font-family: "main-text-regular";
  font-size: 1.125rem;
}

.main-text-regular-20 {
  font-family: "main-text-regular";
  font-size: 1.25rem;
}

.main-text-bold-12 {
  font-family: "main-text-bold";
  font-size: 0.75rem;
}

.main-text-bold-14 {
  font-family: "main-text-bold";
  font-size: 0.875rem;
}

.main-text-bold-16 {
  font-family: "main-text-bold";
  font-size: 1rem;
}

.main-text-bold-18 {
  font-family: "main-text-bold";
  font-size: 1.125rem;
}

.main-text-bold-20 {
  font-family: "main-text-bold";
  font-size: 1.25rem;
}

.main-text-link-12 {
  font-family: "main-text-link";
  font-size: 0.75rem;
}

.main-text-link-14 {
  font-family: "main-text-link";
  font-size: 0.875rem;
}

.main-text-link-16 {
  font-family: "main-text-link";
  font-size: 1rem;
}

.label-text-12 {
  font-family: "label-text";
  font-size: 0.75rem;
}

.label-text-14 {
  font-family: "label-text";
  font-size: 0.875rem;
}

.label-text-16 {
  font-family: "label-text";
  font-size: 1rem;
}

.label-text-18 {
  font-family: "label-text";
  font-size: 1.125rem;
}

.label-text-20 {
  font-family: "label-text";
  font-size: 1.25rem;
}

.label-text-22 {
  font-family: "label-text";
  font-size: 1.375rem;
}

.label-text-24 {
  font-family: "label-text";
  font-size: 1.5rem;
}

.label-text-26 {
  font-family: "label-text";
  font-size: 1.625rem;
}

.label-text-28 {
  font-family: "label-text";
  font-size: 1.75rem;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.is-flex {
  display: flex;
}

.is-row {
  flex-direction: row;
}

.is-column {
  flex-direction: column;
}

.float-right {
  justify-content: flex-end;
}

.float-start {
  justify-content: flex-start;
}

.float-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

a {
  color: var(--fg-pink);
  transition: all 0.4s ease;
  font-family: "main-text-bold";
}
a:hover {
  color: rgb(0, 0, 0);
}

p a {
  color: rgba(137, 126, 108, 0.6);
  transition: all 0.4s ease;
  font-family: "main-text-bold";
}

h1 {
  font-family: "heading";
  font-size: 2.625rem;
}

h2 {
  font-family: "heading";
  font-size: 2rem;
}

h3 {
  font-family: "heading";
  font-size: 1.75rem;
}

h4 {
  font-family: "heading";
  font-size: 1.625rem;
}

h5 {
  font-family: "heading";
  font-size: 1.5rem;
}

h6 {
  font-family: "heading";
  font-size: 1.375rem;
}

/* Uncomment when using atomic-bomb */
.Logo {
  width: 220px;
  height: 220px;
}
@media (max-width: 768px) {
  .Logo {
    width: 140px;
    height: 140px;
  }
}
@media (max-width: 480px) {
  .Logo {
    width: 80px;
    height: 80px;
  }
}

.MenuItem {
  float: left;
  display: block;
  margin: 40px;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  padding-bottom: 6px;
}
.MenuItem a {
  color: var(--fg-black);
  transition: border-bottom 0s;
  padding-bottom: 6px;
  border-bottom: 4px solid transparent;
}
.MenuItem a:hover {
  color: var(--fg-pink);
  border-bottom: 4px solid var(--fg-black);
}
.MenuItem .active {
  color: var(--fg-pink);
  border-bottom: 4px solid var(--fg-black);
}
.SignatureTitle {
  font-family: "heading", sans-serif;
}
.SignatureTitle[data-object-type=signature] {
  color: var(--fg-white);
  font-size: 60px;
}
.SignatureTitle[data-object-type=title] {
  color: var(--fg-pink);
  font-size: 60px;
  margin-bottom: -30px;
}
.SignatureTitle[data-object-type=prompt] {
  color: var(--fg-white);
  font-size: 40px;
}
@media (max-width: 768px) {
  .SignatureTitle[data-object-type=signature] {
    color: var(--fg-white);
    font-size: 30px;
    text-align: right;
  }
}
.Button {
  background-color: var(--bg-pink);
  color: var(--fg-white);
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  transition: var(--transition);
}
.Button:hover {
  color: var(--fg-white);
  background-color: var(--fg-pink-dark-20);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}
.Button[data-object-type=secondary] {
  background-color: transparent;
  padding: 15px 0 0;
  color: var(--fg-pink);
  border-bottom: 2px solid transparent;
}
.Button[data-object-type=secondary]:hover {
  color: var(--fg-white);
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--fg-pink);
}
.Paragraph {
  font-size: 22px;
  line-height: 39.6px;
  padding-left: 80px;
  padding-right: 60px;
  margin-bottom: 20px;
  font-family: "main-text-regular", sans-serif;
}
.Paragraph a {
  color: var(--fg-pink);
  font-size: 22px;
}
.Paragraph[data-object-type=small] {
  font-size: 18px;
  line-height: 28.8px;
  padding: 0;
  margin-bottom: 0;
}
.Paragraph[data-object-type=small] a {
  color: var(--fg-pink);
  font-size: 18px;
}
.Paragraph[data-object-type=large] {
  font-size: 36px;
  line-height: 43.2px;
  color: var(--fg-pink);
  margin-bottom: 40px;
}
.Paragraph[data-object-type=large] a {
  color: var(--fg-pink);
  font-size: 36px;
}
@media (max-width: 768px) {
  .Paragraph {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .Paragraph {
    font-size: 16px;
    line-height: 28.8px;
  }
  .Paragraph a {
    color: var(--fg-pink);
    font-size: 16px;
  }
  .Paragraph[data-object-type=small] {
    font-size: 16px;
    line-height: 22.4px;
    padding: 0;
    margin-bottom: 0;
  }
  .Paragraph[data-object-type=small] a {
    color: var(--fg-pink);
    font-size: 16px;
  }
  .Paragraph[data-object-type=large] {
    font-size: 28px;
    line-height: 33.6px;
    color: var(--fg-pink);
    margin-bottom: 20px;
  }
  .Paragraph[data-object-type=large] a {
    color: var(--fg-pink);
    font-size: 28px;
  }
}
.Heading {
  font-family: "main-text-bold", sans-serif;
  color: var(--fg-pink);
  padding-left: 80px;
  padding-right: 60px;
  margin-bottom: 20px;
}
.Heading[data-object-type=h1] {
  font-size: 2.6rem;
}
.Heading[data-object-type=h2] {
  font-size: 2rem;
}
.Heading[data-object-type=h3] {
  font-size: 1.8rem;
}
.Heading[data-object-type=h4] {
  font-size: 1.6rem;
}
@media (max-width: 768px) {
  .Heading {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .Heading[data-object-type=h1] {
    font-size: 2.6rem;
  }
  .Heading[data-object-type=h2] {
    font-size: 2rem;
  }
  .Heading[data-object-type=h3] {
    font-size: 1.8rem;
  }
  .Heading[data-object-type=h4] {
    font-size: 1.6rem;
  }
}
.Blob {
  border-radius: 49% 51% 65% 35%/67% 53% 47% 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Blob[data-object-type=small] {
  width: 575.6px;
  height: 430px;
  background-color: var(--bg-light-pink);
}
@media (max-width: 1024px) {
  .Blob[data-object-type=small] {
    padding: 80px;
    width: 100%;
    background-color: var(--bg-light-pink);
    margin: 0;
    height: auto;
  }
}
@media (max-width: 480px) {
  .Blob {
    padding: 0;
    gap: 0;
    display: block;
  }
  .Blob[data-object-type=small] {
    padding: 30px 20px !important;
    margin-left: -10px;
    margin-right: -10px;
    width: 110%;
    height: auto;
    background-color: var(--bg-light-pink);
    border-radius: 0;
  }
}
.Hero {
  margin-top: -120px;
  z-index: -10;
  margin-bottom: 0;
}
.Hero video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.Hero .shape-div {
  width: 102%;
  background-color: #FFF; /* Adjust the background color as needed */
  clip-path: url(#clip-shape);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 60vh;
  margin-top: -8vh;
  z-index: 10;
}
.Hero .content-overlay-top {
  min-height: 15vh;
  z-index: 20;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.Hero .content-overlay-bottom {
  min-height: 15vh;
  z-index: 20;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -8vh;
}
.Hero .content-image {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  margin-top: -8vh;
  z-index: 10;
}
.Hero .content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.Hero .content .content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 70%;
  align-items: flex-end;
  float: right;
}
.Hero .content .content-left p {
  font-size: 28px;
  color: var(--fg-white);
  line-height: 42px;
}
.Hero .content .content-left .signature {
  color: var(--fg-white);
  width: 100%;
  text-align: right !important;
  float: right;
}
.Hero .content .content-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 70%;
  align-items: flex-start;
  float: left;
}
.Hero .content .content-right p {
  font-size: 28px;
  color: var(--fg-white);
  line-height: 42px;
}
.Hero .content .content-right .signature {
  color: var(--fg-white);
  width: 100%;
  text-align: right !important;
  float: right;
}
.Hero .brooks {
  padding-top: 40px;
  position: relative;
  font-family: "Sanchez", serif;
  font-size: 2.4em;
  line-height: 1.5em;
  font-style: italic;
}
.Hero .brooks:before {
  content: "“";
  position: absolute;
  z-index: 10000;
  top: 0.3em;
  left: -0.25em;
  color: rgba(255, 255, 255, 0.4);
  font-size: 7em;
}
.Hero .mobile {
  display: none;
}
@media (max-width: 768px) {
  .Hero {
    margin-top: 120px;
  }
  .Hero .shape-div {
    height: 45vh;
    margin-top: -5vh;
  }
  .Hero .mobile {
    padding: 20px;
    display: block;
    font-size: 8px !important;
    color: #FFF;
  }
  .Hero .desktop {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .Hero {
    margin-top: 90px;
  }
  .Hero .shape-div {
    height: 40vh;
    margin-top: -3vh;
  }
}
.Booking {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}
.Booking .ButtonBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .Booking {
    width: 300px;
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  .Booking {
    width: 100%;
    max-width: 100%;
  }
}
.BookAdvertising {
  max-width: 400px;
  padding-top: 40px;
}
.BookAdvertising .book-info {
  margin: 20px 0;
}
.BookAdvertising .book-info p {
  color: var(--fg-white);
}
.BookAdvertising .book-description {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.BookAdvertising .book-description .book-content h3 {
  margin: 20px 0;
  font-family: "main-text-bold", sans-serif;
  font-size: 24px;
}
.BookAdvertising .book-description .book-content h3 .label {
  color: var(--fg-white);
}
.BookAdvertising .book-description .book-content h3 .price {
  color: var(--fg-pink);
}
.BookAdvertising .book-description .book-image {
  width: 120px;
  margin-right: 20px;
}
.BookAdvertising .book-description .book-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 480px) {
  .BookAdvertising .book-description .book-image {
    display: none;
  }
}
.ContactInfo {
  width: 100%;
  height: 100%;
  padding: 20px 20px 80px;
}
.ContactInfo a {
  color: var(--fg-pink);
  text-decoration: none;
  transition: var(--transition);
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
}
.ContactInfo a:hover {
  color: var(--fg-black);
  border-bottom: 2px solid var(--fg-pink);
}
.ContactInfo .left {
  min-height: 100px;
}
.ContactInfo .left h3 {
  font-family: "main-text-bold", sans-serif;
  color: var(--fg-pink);
  font-size: 1rem;
  margin: 0 0 8px;
}
.ContactInfo .right {
  min-height: 100px;
}
.ContactInfo .bottom-links {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: nowrap;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;
}
.ContactInfo .bottom-links i {
  font-size: 32px;
}
@media (max-width: 768px) {
  .ContactInfo {
    display: block;
  }
}
@media (min-width: 769px) {
  .ContactInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: flex-end;
    padding-left: 80px;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 40px;
  z-index: 1000;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
}
@media (max-width: 768px) {
  .Header {
    position: fixed;
    padding: 10px 20px;
  }
}
@media (max-width: 480px) {
  .Header {
    padding: 4px 10px;
  }
}
.Menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.Menu .menu-desktop {
  display: block;
}
.Menu .menu-mobile {
  display: none;
}
.Menu .menu-mobile .menu-trigger {
  z-index: 10001;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: var(--bg-white);
  padding: 4px;
}
.Menu .menu-mobile .overlay {
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding-top: 100px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: var(--transition);
}
.Menu .menu-mobile .overlay ul li {
  width: 80%;
  text-align: center !important;
}
@media (max-width: 768px) {
  .Menu .menu-desktop {
    display: none;
  }
  .Menu .menu-mobile {
    display: block;
  }
}
@media (max-width: 480px) {
  .Menu .menu-desktop {
    display: none;
  }
  .Menu .menu-mobile {
    display: block;
  }
}

.BottomBlob {
  min-width: 100%;
  min-height: 700px;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 20px;
  margin-right: -20px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (min-width: 1025px) {
  .BottomBlob {
    padding-top: 30px;
    padding-right: 20%;
  }
}
@media (max-width: 1024px) {
  .BottomBlob {
    background-color: var(--bg-dark-gray);
    min-height: 200px;
    margin-left: -12px;
    margin-right: -12px;
    padding-right: 53px;
    padding-left: 20px;
  }
}
@media (max-width: 480px) {
  .BottomBlob {
    padding-right: 30px;
    padding-left: 30px;
  }
}

