.ContactInfo {

  width: 100%;
  height: 100%;
  padding: 20px 20px 80px;

  a {
    color: var(--fg-pink);
    text-decoration: none;
    transition: var(--transition);
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;

    &:hover {
      color: var(--fg-black);
      border-bottom: 2px solid var(--fg-pink);
    }
  }

  .left {
    min-height: 100px;

    h3 {
      font-family: 'main-text-bold', sans-serif;
      color: var(--fg-pink);
      font-size: 1rem;
      margin: 0 0 8px;
    }
  }

  .right {
    min-height: 100px;
  }

  .bottom-links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: nowrap;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 32px;
    }
  }



  // M - Tablet/Laptop

  // XS - Phone
  @media (max-width: 480px) {

  }

  // S - Tablet
  @media (max-width: 768px) {
    display: block;
  }

  @media (min-width: 769px) {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: flex-end;
    padding-left: 80px;
  }



}