.Paragraph {

  $size: 22px;
  $line-height: 1.8 * $size;
  font-size: ($size);
  line-height: ($line-height);

  padding-left: 80px;
  padding-right: 60px;
  margin-bottom: 20px;

  font-family: 'main-text-regular', sans-serif;

  a {
    color: var(--fg-pink);
    font-size: $size;
  }

  &[data-object-type='small'] {
    $size: 18px;
    $line-height: 1.6 * $size;
    font-size: ($size);
    line-height: ($line-height);
    padding: 0;
    margin-bottom: 0;
    a {
      color: var(--fg-pink);
      font-size: $size;
    }

  }

  &[data-object-type='large'] {
    $size: 36px;
    $line-height: 1.2 * $size;
    font-size: ($size);
    line-height: ($line-height);
    color: var(--fg-pink);
    margin-bottom: 40px;
    a {
      color: var(--fg-pink);
      font-size: $size;
    }

  }


  // S - Tablet
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }


  // XS - Phone
  @media (max-width: 480px) {
    $size: 16px;
    $line-height: 1.8 * $size;
    font-size: ($size);
    line-height: ($line-height);
    a {
      color: var(--fg-pink);
      font-size: $size;
    }


    &[data-object-type='small'] {
      $size: 16px;
      $line-height: 1.4 * $size;
      font-size: ($size);
      line-height: ($line-height);
      padding: 0;
      margin-bottom: 0;
      a {
        color: var(--fg-pink);
        font-size: $size;
      }

    }

    &[data-object-type='large'] {
      $size: 28px;
      $line-height: 1.2 * $size;
      font-size: ($size);
      line-height: ($line-height);
      color: var(--fg-pink);
      margin-bottom: 20px;
      a {
        color: var(--fg-pink);
        font-size: $size;
      }

    }
  }

  // M - Tablet/Laptop
  @media (max-width: 1024px) {

  }

  // L - High Res
  @media (max-width: 1200px) {

  }

  // XL - Ultra High Res
  @media (min-width: 1201px) {

  }

}