html {
  overflow-x: hidden;
}
body {
    margin: map-get($page, 'margin');
    padding: map-get($page, 'padding');
    background-color: var(--bg- + map-get($page, 'backgroundColor'));
    font-family: 'main-text-regular', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}

* {
  //border:  1px dashed lime;
}